import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { AnimatedSection } from '../common/AnimatedSection';

const About = () => {
  return (
    <section className="py-20">
      <AnimatedSection className="container mx-auto px-4">
        <motion.div 
          className="max-w-4xl mx-auto text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <motion.h2 
            className="text-4xl font-bold mb-8"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            À Propos de Nous
          </motion.h2>
          <motion.p 
            className="text-lg text-gray-600 mb-6"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            Nous sommes passionnés par la musique et l'enseignement de la darbouka. Notre mission est de partager notre amour pour cet instrument et d'offrir des spectacles inoubliables.
          </motion.p>
          <motion.p 
            className="text-lg text-gray-600 mb-6"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            Notre équipe est composée de musiciens expérimentés et d'enseignants dévoués, prêts à transmettre leur savoir et leur passion.
          </motion.p>
          <video
            width="560"
            height="315"
            controls
            className="mx-auto mb-6"
          >
            <source src="/videos/video.mp4" type="video/mp4" />
            Votre navigateur ne supporte pas la lecture de vidéos.
          </video>
          <Link to="/artistes">
            <motion.button 
              className="bg-primary text-white px-6 py-3 rounded-md hover:bg-opacity-90 transition-colors"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.7, duration: 0.5 }}
            >
              En savoir plus sur nos artistes
            </motion.button>
          </Link>
        </motion.div>
      </AnimatedSection>
    </section>
  );
};

export default About; 