import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Services from './components/Services/Services';
import About from './components/About/About';
import Spectacles from './components/Spectacles/Spectacles';
import Enseignement from './components/Enseignement/Enseignement';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Collaborateurs from './components/Collaborateurs/Collaborateurs';
import ProductionMusique from './components/ProductionMusique';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';

function App() {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow">
          <AnimatePresence mode='wait'>
            <Routes>
              <Route path="/" element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Hero />
                  <Services />
                  <About />
                  <Contact />
                </motion.div>
              } />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/spectacles" element={<Spectacles />} />
              <Route path="/production-musique" element={<ProductionMusique />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/collaborateurs" element={<Collaborateurs />} />
              <Route path="/enseignement" element={<Enseignement />} />
            </Routes>
          </AnimatePresence>
        </main>
        <Footer />
      </div>
    </Router>
  );
}
export default App;