import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await addDoc(collection(db, 'newsletter'), {
        email,
        dateInscription: new Date()
      });
      setMessage('Inscription réussie à la newsletter !');
      setEmail('');
    } catch (error) {
      setMessage('Une erreur est survenue. Veuillez réessayer.');
    }
    setIsSubmitting(false);
  };

  const socialLinks = [
    { name: 'Facebook', url: '#', icon: 'fab fa-facebook' },
    { name: 'Instagram', url: '#', icon: 'fab fa-instagram' },
    { name: 'YouTube', url: '#', icon: 'fab fa-youtube' }
  ];

  return (
    <footer className="bg-secondary text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Newsletter */}
          <div>
            <h3 className="text-xl font-heading mb-4">Newsletter</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Votre email"
                  required
                  className="w-full px-4 py-2 rounded-md bg-white/10 border border-white/20 focus:outline-none focus:ring-2 focus:ring-primary placeholder-white/50"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-primary text-white px-6 py-2 rounded-md hover:bg-opacity-90 transition-colors disabled:opacity-50"
              >
                {isSubmitting ? 'Inscription...' : "S'abonner"}
              </button>
              {message && (
                <p className={`text-sm ${message.includes('réussie') ? 'text-green-400' : 'text-red-400'}`}>
                  {message}
                </p>
              )}
            </form>
          </div>

          {/* Liens rapides */}
          <div>
            <h3 className="text-xl font-heading mb-4">Liens rapides</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="hover:text-primary transition-colors">
                  Accueil
                </Link>
              </li>
              <li>
                <Link to="/spectacles" className="hover:text-primary transition-colors">
                  Spectacles
                </Link>
              </li>
              <li>
                <Link to="/enseignement" className="hover:text-primary transition-colors">
                  Enseignement
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-primary transition-colors">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Réseaux sociaux */}
          <div>
            <h3 className="text-xl font-heading mb-4">Suivez-nous</h3>
            <div className="flex space-x-4">
              {socialLinks.map((social) => (
                <motion.a
                  key={social.name}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-2xl hover:text-primary transition-colors"
                  whileHover={{ scale: 1.1 }}
                >
                  <i className={social.icon}></i>
                </motion.a>
              ))}
            </div>
          </div>
        </div>

        {/* Mentions légales et copyright */}
        <div className="mt-12 pt-8 border-t border-white/10 text-center text-sm">
          <div className="space-x-4 mb-4">
            <Link to="/mentions-legales" className="hover:text-primary transition-colors">
              Mentions légales
            </Link>
            <span>|</span>
            <Link to="/politique-confidentialite" className="hover:text-primary transition-colors">
              Politique de confidentialité
            </Link>
          </div>
          <p>
            © {new Date().getFullYear()} dataperpane.fr | Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 