import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { FaArrowLeft, FaArrowRight, FaStar, FaSearch, FaCalendar, FaMapMarkerAlt, FaGuitar } from 'react-icons/fa';
import { db } from '../../firebase/config'; // Importer la configuration Firebase
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { motion, AnimatePresence } from 'framer-motion'; // Importer framer-motion
import { AnimatedSection } from '../common/AnimatedSection';

const Collaborateurs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false); // État pour le chargement
  const [error, setError] = useState(null); // État pour les erreurs
  
  // État pour le filtrage
  const [filter, setFilter] = useState({
    instrument: '',
    name: '',
    department: '',
    availabilityDate: '',
  });

  const collaborateurs = [
    {
      nom: "SAADI Yanis",
      prenom: "Yanis",
      role: "Musicien",
      description: "Expert en darbouka avec plus de 10 ans d'expérience.",
      image: "/images/col1.jpeg",
      instrument: "Darbouka",
      bio: "Passionné par la musique orientale et les percussions.",
      rating: 4.5,
      departement: "Paris",
      availabilityDate: "2023-10-01",
    },
    {
      nom: "Taous Warda",
      prenom: "Warda",
      role: "Professeur de musique",
      description: "Enseigne la musique depuis 15 ans.",
      image: "/images/col2.jpg",
      instrument: "Piano",
      bio: "Passionnée par l'éducation musicale.",
      rating: 4.8,
      departement: "Lyon",
      availabilityDate: "2023-10-05",
    },
    {
      nom: "Lala Khadidja",
      prenom: "Khadidja",
      role: "Technicien du son",
      description: "Responsable de la sonorisation de tous nos spectacles.",
      image: "/images/col3.jpeg",
      instrument: "Son",
      bio: "Expert en sonorisation et en acoustique.",
      rating: 4.7,
      departement: "Marseille",
      availabilityDate: "2023-10-10",
    },
  ];

  // Filtrage des collaborateurs
  const filteredCollaborateurs = collaborateurs.filter((collaborateur) => {
    return (
      (filter.instrument === '' || collaborateur.instrument.toLowerCase().includes(filter.instrument.toLowerCase())) &&
      (filter.name === '' || `${collaborateur.prenom} ${collaborateur.nom}`.toLowerCase().includes(filter.name.toLowerCase())) &&
      (filter.department === '' || collaborateur.departement.toLowerCase().includes(filter.department.toLowerCase())) &&
      (filter.availabilityDate === '' || collaborateur.availabilityDate === filter.availabilityDate)
    );
  });

  // Récupérer les avis depuis Firebase
  const fetchReviews = async () => {
    const reviewsCollection = collection(db, 'reviews');
    const reviewsSnapshot = await getDocs(reviewsCollection);
    const reviewsList = reviewsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReviews(reviewsList);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  // Ajouter un nouvel avis
  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Démarrer le chargement
    setError(null); // Réinitialiser l'erreur
    if (!selectedCollaborator) {
      setError("Aucun collaborateur sélectionné."); // Gérer l'erreur si aucun collaborateur n'est sélectionné
      setLoading(false);
      return;
    }
    try {
      await addDoc(collection(db, 'reviews'), {
        firstName,
        email,
        comment,
        rating,
        collaboratorId: selectedCollaborator.id, // Assurez-vous d'avoir un identifiant pour le collaborateur
      });
      setComment('');
      setRating(0);
      setFirstName('');
      setEmail('');
      fetchReviews(); // Récupérer les avis après l'ajout
    } catch (error) {
      console.error("Error adding review: ", error);
      setError("Une erreur s'est produite lors de l'ajout de l'avis."); // Gérer l'erreur
    } finally {
      setLoading(false); // Arrêter le chargement
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredCollaborateurs.length),
    onSwipedRight: () =>
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + filteredCollaborateurs.length) % filteredCollaborateurs.length
      ),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      <AnimatedSection>
        <section className="py-20">
          <div className="container mx-auto px-4">
            {/* En-tête de la page */}
            <motion.div 
              className="text-center mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h1 className="text-5xl font-bold text-gray-800 mb-4">Nos Collaborateurs</h1>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                Découvrez notre équipe de professionnels passionnés, prêts à donner vie à vos projets musicaux
              </p>
            </motion.div>

            {/* Filtrage Avancé */}
            <motion.div 
              className="bg-white rounded-xl shadow-lg p-6 mb-12"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <h2 className="text-2xl font-semibold mb-6 text-gray-800 flex items-center">
                <FaSearch className="mr-2" /> Vous cherchez quelque chose ?
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                <div className="relative">
                  <FaGuitar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Type d'instrument"
                    value={filter.instrument}
                    onChange={(e) => setFilter({ ...filter, instrument: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
                <div className="relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Nom du collaborateur"
                    value={filter.name}
                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
                <div className="relative">
                  <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Département"
                    value={filter.department}
                    onChange={(e) => setFilter({ ...filter, department: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
                <div className="relative">
                  <FaCalendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="date"
                    value={filter.availabilityDate}
                    onChange={(e) => setFilter({ ...filter, availabilityDate: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
              </div>
            </motion.div>

            {/* Slider des collaborateurs */}
            <div {...handlers} className="relative mb-20">
              <AnimatePresence mode="wait">
                {filteredCollaborateurs.length > 0 ? (
                  <motion.div
                    key={currentIndex}
                    className="bg-white rounded-2xl shadow-xl overflow-hidden"
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="md:flex">
                      <div className="md:w-1/2">
                        <img
                          src={filteredCollaborateurs[currentIndex].image}
                          alt={filteredCollaborateurs[currentIndex].nom}
                          className="w-full h-[400px] object-cover"
                        />
                      </div>
                      <div className="md:w-1/2 p-8">
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.3 }}
                        >
                          <h3 className="text-3xl font-bold text-gray-800 mb-4">
                            {filteredCollaborateurs[currentIndex].prenom} {filteredCollaborateurs[currentIndex].nom}
                          </h3>
                          <div className="flex items-center mb-4">
                            <span className="bg-primary/10 text-primary px-4 py-1 rounded-full text-sm font-medium">
                              {filteredCollaborateurs[currentIndex].instrument}
                            </span>
                            <span className="ml-4 text-gray-600">
                              <FaMapMarkerAlt className="inline mr-1" />
                              {filteredCollaborateurs[currentIndex].departement}
                            </span>
                          </div>
                          <p className="text-gray-600 mb-6 leading-relaxed">
                            {filteredCollaborateurs[currentIndex].description}
                          </p>
                          <div className="flex items-center">
                            <div className="text-yellow-400 text-xl">
                              {'★'.repeat(Math.floor(filteredCollaborateurs[currentIndex].rating))}
                              {'☆'.repeat(5 - Math.floor(filteredCollaborateurs[currentIndex].rating))}
                            </div>
                            <span className="ml-2 text-gray-600">
                              {filteredCollaborateurs[currentIndex].rating.toFixed(1)}/5
                            </span>
                          </div>
                        </motion.div>
                      </div>
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="bg-white rounded-2xl shadow-xl p-8 text-center"
                  >
                    <div className="text-gray-500 text-xl">
                      <FaSearch className="inline-block mr-2 mb-1" />
                      Aucun collaborateur ne correspond à votre recherche
                    </div>
                    <button
                      onClick={() => setFilter({
                        instrument: '',
                        name: '',
                        department: '',
                        availabilityDate: '',
                      })}
                      className="mt-4 text-primary hover:text-primary-dark transition-colors"
                    >
                      Réinitialiser les filtres
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>

              {filteredCollaborateurs.length > 0 && (
                <>
                  <button
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/80 hover:bg-white w-12 h-12 rounded-full shadow-lg flex items-center justify-center transition-all"
                    onClick={() => setCurrentIndex((currentIndex - 1 + filteredCollaborateurs.length) % filteredCollaborateurs.length)}
                  >
                    <FaArrowLeft className="text-gray-800" />
                  </button>
                  <button
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/80 hover:bg-white w-12 h-12 rounded-full shadow-lg flex items-center justify-center transition-all"
                    onClick={() => setCurrentIndex((currentIndex + 1) % filteredCollaborateurs.length)}
                  >
                    <FaArrowRight className="text-gray-800" />
                  </button>
                </>
              )}
            </div>

            {/* Section des avis */}
            <div className="mb-20">
              <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Avis de nos clients</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {reviews.map((review) => (
                  <motion.div
                    key={review.id}
                    className="bg-white rounded-xl shadow-lg p-6 transform hover:-translate-y-2 transition-all duration-300"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                  >
                    <div className="flex items-center mb-4">
                      <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center text-primary font-bold">
                        {review.firstName.charAt(0)}
                      </div>
                      <div className="ml-4">
                        <h4 className="font-semibold text-gray-800">{review.firstName}</h4>
                        <div className="text-yellow-400">
                          {'★'.repeat(review.rating)}
                          {'☆'.repeat(5 - review.rating)}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600 italic">"{review.comment}"</p>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Formulaire d'avis */}
            <motion.div 
              className="bg-white rounded-xl shadow-lg p-8 max-w-2xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Laissez votre avis</h2>
              <form onSubmit={handleReviewSubmit} className="space-y-6">
                <input
                  type="text"
                  placeholder="Prénom"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="border border-gray-300 rounded-md px-4 py-2 mb-2 w-full"
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-300 rounded-md px-4 py-2 mb-2 w-full"
                  required
                />
                <textarea
                  placeholder="Laissez un commentaire..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="border border-gray-300 rounded-md px-4 py-2 mb-2 w-full"
                  required
                />
                <div className="flex mb-4">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`cursor-pointer ${star <= rating ? 'text-yellow-500' : 'text-gray-300'}`}
                      onClick={() => setRating(star)}
                    >
                      <FaStar />
                    </span>
                  ))}
                </div>
                <button type="submit" className="bg-primary text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors" disabled={loading}>
                  {loading ? 'Envoi...' : 'Soumettre'}
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>} {/* Afficher l'erreur si elle existe */}
              </form>
            </motion.div>
          </div>
        </section>
      </AnimatedSection>
    </div>
  );
};

export default Collaborateurs;
