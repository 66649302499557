import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';

const Enseignement = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    niveau: 'debutant',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await addDoc(collection(db, 'inscriptions'), {
        nom: formData.nom,
        prenom: formData.prenom,
        email: formData.email,
        telephone: formData.telephone,
        niveau: formData.niveau,
      });
      setMessage('Votre inscription a été enregistrée avec succès !');
      setFormData({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        niveau: 'debutant',
      });
    } catch (error) {
      console.error("Erreur lors de l'envoi des données : ", error);
      setMessage('Une erreur est survenue. Veuillez réessayer.');
    }
    setIsSubmitting(false);
  };

  return (
    <section className="py-20 bg-background">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto"
        >
          <h2 className="text-4xl font-heading text-secondary mb-8 text-center">
            Stage et atelier de percussions
          </h2>
          <h3 className="text-2xl font-heading text-primary mb-4 text-center">
            Rejoignez-nous pour une expérience rythmique inoubliable !
          </h3>

          <div className="flex flex-col md:flex-row">
            {/* Formulaire d'inscription */}
            <div className="bg-white rounded-lg shadow-lg p-8 md:w-1/2">
              <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-gray-700 mb-2">Nom</label>
                    <input
                      type="text"
                      name="nom"
                      value={formData.nom}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary transition duration-200"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Prénom</label>
                    <input
                      type="text"
                      name="prenom"
                      value={formData.prenom}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary transition duration-200"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary transition duration-200"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Téléphone</label>
                    <input
                      type="tel"
                      name="telephone"
                      value={formData.telephone}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary transition duration-200"
                    />
                  </div>
                  <div className="md:col-span-2">
                    <label className="block text-gray-700 mb-2">Niveau</label>
                    <select
                      name="niveau"
                      value={formData.niveau}
                      onChange={handleChange}
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary transition duration-200"
                    >
                      <option value="debutant">Débutant</option>
                      <option value="intermediaire">Intermédiaire</option>
                      <option value="avance">Avancé</option>
                    </select>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-6 w-full bg-primary text-white py-3 rounded-md hover:bg-opacity-90 transition-colors disabled:opacity-50"
                >
                  {isSubmitting ? 'Inscription en cours...' : "S'inscrire"}
                </button>

                {message && (
                  <p className={`mt-4 text-center ${message.includes('succès') ? 'text-green-600' : 'text-red-600'}`}>
                    {message}
                  </p>
                )}
              </form>
            </div>

            {/* Affiche de l'atelier */}
            <div className="md:w-1/2">
              <img
                src="/images/atelier.jpg" // Remplacez par le chemin de votre affiche
                alt="Affiche de l'atelier de percussions"
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Enseignement; 