import React, { useState } from "react";
import { motion } from "framer-motion";

const Spectacles = () => {
  const spectacles = [
    {
      id: 1,
      date: "2024-04-15",
      lieu: "Théâtre Municipal",
      titre: "Nuit de la Darbouka",
      description: "Une soirée exceptionnelle dédiée aux rythmes traditionnels",
      adresse: "123 Rue de la Musique, Paris, France",
      image: "/images/spectacle1.jpeg",
    },
    {
      id: 2,
      date: "2024-05-01",
      lieu: "Centre Culturel",
      titre: "Fusion Orientale",
      description: "Mélange de musiques traditionnelles et contemporaines",
      adresse: "456 Avenue de la Culture, Paris, France",
      image: "/images/spectacle2.jpg",
    },
  ];

  const [formData, setFormData] = useState({
    nom: "",
    email: "",
    telephone: "",
    spectacleId: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      // Simuler l'envoi de données
      console.log("Inscription:", formData);
      setMessage("Votre inscription a été enregistrée avec succès !");
      setFormData({
        nom: "",
        email: "",
        telephone: "",
        spectacleId: null,
      });
    } catch (error) {
      setMessage("Une erreur est survenue. Veuillez réessayer.");
    }
    setIsSubmitting(false);
  };

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <h2 className="text-4xl font-heading text-secondary mb-12 text-center">
            Prochains Spectacles
          </h2>

          <div className="grid md:grid-cols-2 gap-8">
            {spectacles.map((spectacle) => (
              <motion.div
                key={spectacle.id}
                className="bg-background rounded-lg shadow-md p-6 flex flex-col"
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.2 }}
              >
                <div className="text-accent font-semibold mb-2">
                  {new Date(spectacle.date).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </div>
                <h3 className="text-xl font-heading text-secondary mb-2">
                  {spectacle.titre}
                </h3>
                <p className="text-gray-600 mb-2">{spectacle.lieu}</p>
                <p className="text-gray-700 mb-4">{spectacle.description}</p>
                <p className="text-gray-700 mb-4">
                  Adresse : {spectacle.adresse}
                </p>
                
                <img
                  src={spectacle.image}
                  alt={spectacle.titre}
                  className="w-full h-48 object-cover rounded-md mb-4"
                />

                <form onSubmit={handleSubmit} className="space-y-4">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Votre email"
                    required
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  />
                  <input
                    type="text"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleChange}
                    placeholder="Votre numéro de téléphone"
                    required
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  />
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-primary text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors disabled:opacity-50"
                  >
                    {isSubmitting ? "Inscription..." : "S'inscrire"}
                  </button>
                </form>
                {message && (
                  <p
                    className={`mt-4 text-center ${
                      message.includes("succès")
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {message}
                  </p>
                )}
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Spectacles;
