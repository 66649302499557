import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const menuItems = [
    { title: 'Accueil', path: '/' },
    { title: 'Collaborateurs', path: '/collaborateurs' },
    {
      title: 'Services',
      path: '/services',
      submenu: [
        { title: 'Spectacles', path: '/spectacles' },
        { title: 'Production Musique', path: '/production-musique' },
        { title: 'Enseignement', path: '/enseignement' },
      ],
    },
    { title: 'À Propos', path: '/about' },
    { title: 'Contactez-nous', path: '/contact' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <motion.header
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'
      }`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <nav className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <Link to="/" className="text-2xl font-heading font-bold text-primary">
            {isHomePage && !isScrolled ? <img src="/images/logo3.png" className="h-12 w-auto" alt="Logo" /> :  <img src="/images/logo2.png" className="h-12 w-auto" alt="Logo" />}
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map((item, index) => (
              <div key={index} className="relative group">
                {item.submenu ? (
                  <div
                    className="flex items-center cursor-pointer"
                    onMouseEnter={() => handleDropdown(index)}
                    onMouseLeave={() => handleDropdown(null)}
                  >
                    <span
                      className={`${
                        isScrolled ? 'text-gray-800' : 'text-white'
                      } hover:text-primary transition-colors flex items-center`}
                    >
                      {item.title}
                      <FaChevronDown className="ml-1 text-sm" />
                    </span>
                    <AnimatePresence>
                      {activeDropdown === index && (
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          className="absolute top-full left-0 w-48 py-2 mt-2 bg-white rounded-lg shadow-xl"
                        >
                          {item.submenu.map((subItem, subIndex) => (
                            <Link
                              key={subIndex}
                              to={subItem.path}
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-primary transition-colors"
                            >
                              {subItem.title}
                            </Link>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <Link
                    to={item.path}
                    className={`${
                      isScrolled ? 'text-gray-800' : 'text-white'
                    } hover:text-primary transition-colors ${
                      location.pathname === item.path ? 'font-semibold text-primary' : ''
                    }`}
                  >
                    {item.title}
                  </Link>
                )}
              </div>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-2xl"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle menu"
          >
            {isOpen ? (
              <FaTimes className={isScrolled ? 'text-gray-800' : 'text-white'} />
            ) : (
              <FaBars className={isScrolled ? 'text-gray-800' : 'text-white'} />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden bg-white rounded-b-lg shadow-lg overflow-hidden"
            >
              {menuItems.map((item, index) => (
                <div key={index}>
                  {item.submenu ? (
                    <div>
                      <button
                        onClick={() => handleDropdown(index)}
                        className="w-full px-4 py-3 text-left text-gray-800 hover:bg-gray-100 flex justify-between items-center"
                      >
                        {item.title}
                        <FaChevronDown
                          className={`transform transition-transform ${
                            activeDropdown === index ? 'rotate-180' : ''
                          }`}
                        />
                      </button>
                      <AnimatePresence>
                        {activeDropdown === index && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            className="bg-gray-50"
                          >
                            {item.submenu.map((subItem, subIndex) => (
                              <Link
                                key={subIndex}
                                to={subItem.path}
                                className="block px-8 py-2 text-gray-600 hover:bg-gray-100"
                                onClick={() => setIsOpen(false)}
                              >
                                {subItem.title}
                              </Link>
                            ))}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ) : (
                    <Link
                      to={item.path}
                      className={`block px-4 py-3 text-gray-800 hover:bg-gray-100 ${
                        location.pathname === item.path ? 'bg-gray-50 font-semibold' : ''
                      }`}
                      onClick={() => setIsOpen(false)}
                    >
                      {item.title}
                    </Link>
                  )}
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </motion.header>
  );
};

export default Header;