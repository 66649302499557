import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { AnimatedSection } from '../common/AnimatedSection';


const services = [
  {
    title: 'Spectacles',
    description: 'Découvrez nos spectacles passés et à venir, avec des performances captivantes qui vous transporteront.',
    image: '/images/spec1.jpg',
    link: '/spectacles',
  },
  {
    title: 'Production de musique et réalisation des singles',
    description: 'Réaliser vos singles et musique grâce à notre expérience et prof.',
    image: '/images/spec2.jpg',
    link: '/production-musique',
  },
  {
    title: 'Stage et atelier de percussions',
    description: 'Apprenez à jouer de la darbouka avec nos cours adaptés à tous les niveaux.',
    image: '/images/spec3.jpg',
    link: '/enseignement',
  },
];

const Services = () => {
  return (
    <section className="py-20 bg-white">
      <AnimatedSection className="container mx-auto px-4">
        <h2 className="text-4xl font-heading text-secondary mb-12 text-center">
          Nos Services
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service) => (
            <motion.div
              key={service.title}
              className="bg-background rounded-lg shadow-md p-6 text-center flex flex-col items-center"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <img src={service.image} alt={service.title} className="w-full h-48 object-cover rounded-md mb-4" />
              <h3 className="text-xl font-heading text-secondary mb-3">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
              <Link to={service.link}>
                <button className="mt-4 bg-primary text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors">
                  EN SAVOIR PLUS
                </button>
              </Link>
            </motion.div>
          ))}
        </div>
      </AnimatedSection>
    </section>
  );
};

export default Services; 