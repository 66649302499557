import React, { useState } from "react";
import { motion } from "framer-motion";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { AnimatedSection } from '../common/AnimatedSection';

const Contact = () => {
  const [formData, setFormData] = useState({
    nom: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await addDoc(collection(db, "messages"), {
        nom: formData.nom,
        email: formData.email,
        message: formData.message,
        dateEnvoi: new Date(),
      });
      setMessage("Votre message a été envoyé avec succès !");
      setFormData({
        nom: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Erreur lors de l'envoi des données : ", error);
      setMessage("Une erreur est survenue. Veuillez réessayer.");
    }
    setIsSubmitting(false);
  };

  const socialLinks = [
    { name: "Facebook", url: "#", icon: "fab fa-facebook" },
    { name: "Instagram", url: "#", icon: "fab fa-instagram" },
    { name: "YouTube", url: "#", icon: "fab fa-youtube" },
  ];

  return (
    <section className="py-20 bg-gray-50">
      <AnimatedSection className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          Contactez-nous
        </motion.h2>
        <motion.div 
          className="max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <form onSubmit={handleSubmit} className="space-y-6">
            <input
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
              placeholder="Votre nom"
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Votre email"
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Votre message"
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              rows="4"
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-primary text-white py-3 rounded-md hover:bg-opacity-90 transition-colors disabled:opacity-50"
            >
              {isSubmitting ? "Envoi en cours..." : "Envoyer"}
            </button>
            {message && (
              <p
                className={`mt-4 text-center ${
                  message.includes("succès") ? "text-green-600" : "text-red-600"
                }`}
              >
                {message}
              </p>
            )}
          </form>
        </motion.div>
      </AnimatedSection>
      {/* Google Maps */}
      <div className="mt-12">
        <h3 className="text-2xl font-heading text-secondary mb-4 text-center">
          Notre Localisation
        </h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2646.6577730368317!2d1.4790536769801461!3d48.44390767127903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e40c43f8d44d4b%3A0x6f6dff8fd843b301!2s34%20Rue%20du%20Grand%20Faubourg%2C%2028000%20Chartres%2C%20France!5e0!3m2!1sfr!2sdz!4v1739292920425!5m2!1sfr!2sdz"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          className="mt-4"
        ></iframe>
      </div>
      {/* Informations de contact */}
      <div className="mt-12 text-center">
        <h3 className="text-2xl font-heading text-secondary mb-4">
          Informations de Contact
        </h3>
        <p className="text-lg text-gray-700">
          📍 Adresse : 34 rue du grand faubourg, 28000 Chartres
        </p>
        <p className="text-lg text-gray-700">
          📞 Téléphone : +33 7 69 99 01 40
        </p>
        <p className="text-lg text-gray-700">
          ✉️ Email : contact@dataperpane.fr{" "}
        </p>
      </div>
    </section>
  );
};

export default Contact;
