import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBxA4YJsok6--uxwukAd0X27hTuxQ2Bueg",
  authDomain: "dataperpane.firebaseapp.com",
  projectId: "dataperpane",
  storageBucket: "dataperpane.firebasestorage.app",
  messagingSenderId: "718180820061",
  appId: "1:718180820061:web:59f34ff82f27d5d36c59b4"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); 