import React from 'react';
import { motion } from 'framer-motion';

const ProductionMusique = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto text-center"
        >
          <h2 className="text-4xl font-heading text-secondary mb-8">
            Production de Musique et Réalisation des Singles
          </h2>
          <p className="text-lg mb-6">
            Réaliser vos singles et musique grâce à notre expérience et professionnalisme.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <motion.div
            className="bg-gray-100 rounded-lg shadow-md p-6 text-center"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <img src="/images/prod1.webp" alt="Production de musique" className="w-full h-48 object-cover rounded-md mb-4" />
            <h3 className="text-xl font-semibold mb-2">Création de Singles</h3>
            <p className="text-gray-600">Nous vous accompagnons dans la création de vos singles, de l'écriture à l'enregistrement.</p>
          </motion.div>

          <motion.div
            className="bg-gray-100 rounded-lg shadow-md p-6 text-center"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <img src="/images/prod2.jpg" alt="Enregistrement en studio" className="w-full h-48 object-cover rounded-md mb-4" />
            <h3 className="text-xl font-semibold mb-2">Enregistrement en Studio</h3>
            <p className="text-gray-600">Profitez de notre studio d'enregistrement professionnel pour donner vie à votre musique.</p>
          </motion.div>

          <motion.div
            className="bg-gray-100 rounded-lg shadow-md p-6 text-center"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <img src="/images/prod3.jpg" alt="Mixage et Mastering" className="w-full h-48 object-cover rounded-md mb-4" />
            <h3 className="text-xl font-semibold mb-2">Mixage et Mastering</h3>
            <p className="text-gray-600">Nous offrons des services de mixage et mastering pour un son professionnel.</p>
          </motion.div>
        </div>

        <div className="mt-12 text-center">
          <h3 className="text-2xl font-heading text-secondary mb-4">Pourquoi Choisir Nos Services ?</h3>
          <p className="text-lg mb-4">Avec des années d'expérience dans l'industrie musicale, nous nous engageons à vous fournir un service de qualité supérieure.</p>
          <p className="text-lg mb-4">Contactez-nous dès aujourd'hui pour discuter de votre projet musical !</p>
          <a href="/contact">
            <button className="bg-primary text-white px-6 py-3 rounded-md hover:bg-opacity-90 transition-colors">
              Contactez-nous
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ProductionMusique; 